export const Fix = () => import('../../components/Fix.vue' /* webpackChunkName: "components/fix" */).then(c => wrapFunctional(c.default || c))
export const TheCheat = () => import('../../components/TheCheat.vue' /* webpackChunkName: "components/the-cheat" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheGame = () => import('../../components/TheGame.vue' /* webpackChunkName: "components/the-game" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const VkMessages = () => import('../../components/VkMessages.vue' /* webpackChunkName: "components/vk-messages" */).then(c => wrapFunctional(c.default || c))
export const AdminContentEditor = () => import('../../components/admin/ContentEditor.vue' /* webpackChunkName: "components/admin-content-editor" */).then(c => wrapFunctional(c.default || c))
export const AdminHeader = () => import('../../components/admin/Header.vue' /* webpackChunkName: "components/admin-header" */).then(c => wrapFunctional(c.default || c))
export const AdminLogin = () => import('../../components/admin/Login.vue' /* webpackChunkName: "components/admin-login" */).then(c => wrapFunctional(c.default || c))
export const Blog = () => import('../../components/blog/Blog.vue' /* webpackChunkName: "components/blog" */).then(c => wrapFunctional(c.default || c))
export const BlogPostFull = () => import('../../components/blog/PostFull.vue' /* webpackChunkName: "components/blog-post-full" */).then(c => wrapFunctional(c.default || c))
export const BlogPostPreview = () => import('../../components/blog/PostPreview.vue' /* webpackChunkName: "components/blog-post-preview" */).then(c => wrapFunctional(c.default || c))
export const CheatsCheatActions = () => import('../../components/cheats/CheatActions.vue' /* webpackChunkName: "components/cheats-cheat-actions" */).then(c => wrapFunctional(c.default || c))
export const CheatsCheatAdditionalInfo = () => import('../../components/cheats/CheatAdditionalInfo.vue' /* webpackChunkName: "components/cheats-cheat-additional-info" */).then(c => wrapFunctional(c.default || c))
export const CheatsCheatFeatures = () => import('../../components/cheats/CheatFeatures.vue' /* webpackChunkName: "components/cheats-cheat-features" */).then(c => wrapFunctional(c.default || c))
export const CheatsCheatInfo = () => import('../../components/cheats/CheatInfo.vue' /* webpackChunkName: "components/cheats-cheat-info" */).then(c => wrapFunctional(c.default || c))
export const CheatsCheatReview = () => import('../../components/cheats/CheatReview.vue' /* webpackChunkName: "components/cheats-cheat-review" */).then(c => wrapFunctional(c.default || c))
export const CheatsCheatScreenshots = () => import('../../components/cheats/CheatScreenshots.vue' /* webpackChunkName: "components/cheats-cheat-screenshots" */).then(c => wrapFunctional(c.default || c))
export const CheatsCheatSystemRequirements = () => import('../../components/cheats/CheatSystemRequirements.vue' /* webpackChunkName: "components/cheats-cheat-system-requirements" */).then(c => wrapFunctional(c.default || c))
export const CheatsPriceChanger = () => import('../../components/cheats/PriceChanger.vue' /* webpackChunkName: "components/cheats-price-changer" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheAdvantages = () => import('../../components/index_page/TheAdvantages.vue' /* webpackChunkName: "components/index-page-the-advantages" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheCheatCards = () => import('../../components/index_page/TheCheatCards.vue' /* webpackChunkName: "components/index-page-the-cheat-cards" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheCheatPanel = () => import('../../components/index_page/TheCheatPanel.vue' /* webpackChunkName: "components/index-page-the-cheat-panel" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheDescription = () => import('../../components/index_page/TheDescription.vue' /* webpackChunkName: "components/index-page-the-description" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheGameCategories = () => import('../../components/index_page/TheGameCategories.vue' /* webpackChunkName: "components/index-page-the-game-categories" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheLastCheats = () => import('../../components/index_page/TheLastCheats.vue' /* webpackChunkName: "components/index-page-the-last-cheats" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheLastNews = () => import('../../components/index_page/TheLastNews.vue' /* webpackChunkName: "components/index-page-the-last-news" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheMainHeading = () => import('../../components/index_page/TheMainHeading.vue' /* webpackChunkName: "components/index-page-the-main-heading" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheReviews = () => import('../../components/index_page/TheReviews.vue' /* webpackChunkName: "components/index-page-the-reviews" */).then(c => wrapFunctional(c.default || c))
export const IndexPageTheSafeHacks = () => import('../../components/index_page/TheSafeHacks.vue' /* webpackChunkName: "components/index-page-the-safe-hacks" */).then(c => wrapFunctional(c.default || c))
export const UiB2PayLogo = () => import('../../components/ui/B2PayLogo.vue' /* webpackChunkName: "components/ui-b2-pay-logo" */).then(c => wrapFunctional(c.default || c))
export const UiBreadcrumbs = () => import('../../components/ui/Breadcrumbs.vue' /* webpackChunkName: "components/ui-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/Pagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiPhoenixLogotype = () => import('../../components/ui/PhoenixLogotype.vue' /* webpackChunkName: "components/ui-phoenix-logotype" */).then(c => wrapFunctional(c.default || c))
export const UiShowPostsListsButton = () => import('../../components/ui/ShowPostsListsButton.vue' /* webpackChunkName: "components/ui-show-posts-lists-button" */).then(c => wrapFunctional(c.default || c))
export const AdminButtonsButtonCreate = () => import('../../components/admin/buttons/ButtonCreate.vue' /* webpackChunkName: "components/admin-buttons-button-create" */).then(c => wrapFunctional(c.default || c))
export const AdminButtonsButtonEdit = () => import('../../components/admin/buttons/ButtonEdit.vue' /* webpackChunkName: "components/admin-buttons-button-edit" */).then(c => wrapFunctional(c.default || c))
export const AdminButtonsButtonList = () => import('../../components/admin/buttons/ButtonList.vue' /* webpackChunkName: "components/admin-buttons-button-list" */).then(c => wrapFunctional(c.default || c))
export const AdminCategoriesList = () => import('../../components/admin/categories/CategoriesList.vue' /* webpackChunkName: "components/admin-categories-list" */).then(c => wrapFunctional(c.default || c))
export const AdminCategoriesCreateCategoryForm = () => import('../../components/admin/categories/CreateCategoryForm.vue' /* webpackChunkName: "components/admin-categories-create-category-form" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsAdvantages = () => import('../../components/admin/cheats/Advantages.vue' /* webpackChunkName: "components/admin-cheats-advantages" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsList = () => import('../../components/admin/cheats/CheatsList.vue' /* webpackChunkName: "components/admin-cheats-list" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsCreateCheat = () => import('../../components/admin/cheats/CreateCheat.vue' /* webpackChunkName: "components/admin-cheats-create-cheat" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsFeatures = () => import('../../components/admin/cheats/Features.vue' /* webpackChunkName: "components/admin-cheats-features" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsGeneralFields = () => import('../../components/admin/cheats/GeneralFields.vue' /* webpackChunkName: "components/admin-cheats-general-fields" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsMetaTags = () => import('../../components/admin/cheats/MetaTags.vue' /* webpackChunkName: "components/admin-cheats-meta-tags" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsPricePlans = () => import('../../components/admin/cheats/PricePlans.vue' /* webpackChunkName: "components/admin-cheats-price-plans" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsScreenshots = () => import('../../components/admin/cheats/Screenshots.vue' /* webpackChunkName: "components/admin-cheats-screenshots" */).then(c => wrapFunctional(c.default || c))
export const AdminCheatsSystemRequirements = () => import('../../components/admin/cheats/SystemRequirements.vue' /* webpackChunkName: "components/admin-cheats-system-requirements" */).then(c => wrapFunctional(c.default || c))
export const AdminFilesFileAdditionZone = () => import('../../components/admin/files/FileAdditionZone.vue' /* webpackChunkName: "components/admin-files-file-addition-zone" */).then(c => wrapFunctional(c.default || c))
export const AdminFilesFileList = () => import('../../components/admin/files/FileList.vue' /* webpackChunkName: "components/admin-files-file-list" */).then(c => wrapFunctional(c.default || c))
export const AdminGamesCreateGame = () => import('../../components/admin/games/CreateGame.vue' /* webpackChunkName: "components/admin-games-create-game" */).then(c => wrapFunctional(c.default || c))
export const AdminGamesList = () => import('../../components/admin/games/GamesList.vue' /* webpackChunkName: "components/admin-games-list" */).then(c => wrapFunctional(c.default || c))
export const AdminGamesGeneralFields = () => import('../../components/admin/games/GeneralFields.vue' /* webpackChunkName: "components/admin-games-general-fields" */).then(c => wrapFunctional(c.default || c))
export const AdminGamesMetaTags = () => import('../../components/admin/games/MetaTags.vue' /* webpackChunkName: "components/admin-games-meta-tags" */).then(c => wrapFunctional(c.default || c))
export const AdminGamesSecondaryTexts = () => import('../../components/admin/games/SecondaryTexts.vue' /* webpackChunkName: "components/admin-games-secondary-texts" */).then(c => wrapFunctional(c.default || c))
export const AdminPagesContentEditor = () => import('../../components/admin/pages/ContentEditor.vue' /* webpackChunkName: "components/admin-pages-content-editor" */).then(c => wrapFunctional(c.default || c))
export const AdminPagesCreatePageForm = () => import('../../components/admin/pages/CreatePageForm.vue' /* webpackChunkName: "components/admin-pages-create-page-form" */).then(c => wrapFunctional(c.default || c))
export const AdminPagesMediaFilesModal = () => import('../../components/admin/pages/MediaFilesModal.vue' /* webpackChunkName: "components/admin-pages-media-files-modal" */).then(c => wrapFunctional(c.default || c))
export const AdminPagesPageTranslatableFields = () => import('../../components/admin/pages/PageTranslatableFields.vue' /* webpackChunkName: "components/admin-pages-page-translatable-fields" */).then(c => wrapFunctional(c.default || c))
export const AdminPagesList = () => import('../../components/admin/pages/PagesList.vue' /* webpackChunkName: "components/admin-pages-list" */).then(c => wrapFunctional(c.default || c))
export const AdminPostsContentEditor = () => import('../../components/admin/posts/ContentEditor.vue' /* webpackChunkName: "components/admin-posts-content-editor" */).then(c => wrapFunctional(c.default || c))
export const AdminPostsCreatePostForm = () => import('../../components/admin/posts/CreatePostForm.vue' /* webpackChunkName: "components/admin-posts-create-post-form" */).then(c => wrapFunctional(c.default || c))
export const AdminPostsMediaFilesModal = () => import('../../components/admin/posts/MediaFilesModal.vue' /* webpackChunkName: "components/admin-posts-media-files-modal" */).then(c => wrapFunctional(c.default || c))
export const AdminPostsPostTranslatableFields = () => import('../../components/admin/posts/PostTranslatableFields.vue' /* webpackChunkName: "components/admin-posts-post-translatable-fields" */).then(c => wrapFunctional(c.default || c))
export const AdminPostsList = () => import('../../components/admin/posts/PostsList.vue' /* webpackChunkName: "components/admin-posts-list" */).then(c => wrapFunctional(c.default || c))
export const AdminUiLoader = () => import('../../components/admin/ui/Loader.vue' /* webpackChunkName: "components/admin-ui-loader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
